export const formatTime = (time) => {
  if (time != null) {
    const date = new Date(time * 1000)
    const YY = date.getFullYear()
    const MM =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    const DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const mm =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const ss =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return YY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss
  } else {
    return ''
  }
}

export const showMathJax = (el) => {
  if (!window.MathJax) {
    const script = document.createElement('script')
    script.src =
      'https://cdn.bootcdn.net/ajax/libs/mathjax/3.2.0/es5/tex-chtml.js'
    script.async = true
    document.head.appendChild(script)
    window.MathJax = {
      tex: {
        inlineMath: [['$', '$']],
        displayMath: [['$$', '$$']],
        processEnvironments: true,
        processRefs: true
      },
      options: {
        skipHtmlTags: ['noscript', 'style', 'textarea', 'pre', 'code'],
        ignoreHtmlClass: 'tex2jax_ignore'
      },
      startup: {
        pageReady: () => {
          window.MathJax.typesetPromise(el)
        }
      },
      svg: {
        fontCache: 'global'
      }
    }
  } else {
    window.MathJax.startup.promise = window.MathJax.startup.promise
      .then(() => window.MathJax.typesetPromise(el))
      .catch((err) => console.log('Typeset failed: ' + err.message))
    return window.MathJax.startup.promise
  }
}

export const isWechat = () => {
  return /MicroMessenger/i.test(window.navigator.userAgent)
}

export const isPhone = (v) => {
  return /^1[3-9]\d{9}$/i.test(v)
}

export const confirmPassword = (v) => {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{6,}$/i.test(v)
}
